<template>
    <div style="min-height:100vh;background:#F8F8F8">
        <!-- <div style="width:100%;height:25px;"></div> -->

        <div class="upload">
            <div class="">银行卡信息</div>
            <!-- <div class="cont_fa_on_rt2"></div> -->
            <!-- <input class="cont_fa_on_rt2" type="text" placeholder="请输入"> -->
            <van-uploader :after-read="getimg">
                <div class="title_top_rt"></div>
            </van-uploader>
        </div>
        <div class="cont_fa">
            <div class="cont_fa_on">
                <div class="cont_fa_on_lt">银行名称<span v-if="is_branchbank" style="color:red">*</span></div>
                <input class="cont_fa_on_rt" v-model="details.bank_name" type="text" placeholder="请填写">
            </div>
        </div>
        <div class="cont_fa">
            <div class="cont_fa_on">
                <div class="cont_fa_on_lt">银行卡号<span v-if="is_branchbank" style="color:red">*</span></div>
                <input class="cont_fa_on_rt" v-model="details.bank_card_num" @input="details.bank_card_num = details.bank_card_num.replace(/[^0-9]/g, '')" type="text" placeholder="请填写">
            </div>
        </div>
        <div class="cont_fa">
            <div class="cont_fa_on">
                <div class="cont_fa_on_lt">开户行<span v-if="is_branchbank" style="color:red">*</span></div>
                <input class="cont_fa_on_rt" v-model="details.branch_bank" type="text" placeholder="请填写">
            </div>
        </div>
        <div class="btn_sty" @click="save" :class="is_click ? 'd8' : ''">确认</div>
        <!-- 底部 -->
        <div class="footer-bottom"></div>
        <!-- <div class="footer">
            <div @click="save" :style="is_click ? 'background:#aaa' : ''">确认</div>
        </div> -->
        <!-- 弹窗 -->
        <div class="cancl_tan" v-if="my_lizhi">
            <div class="cancl_tan_on">
                <div class="cancl_tan_on_title">绑定手机号码</div>
                <div class="cancl_tan_on_cont">
                    <div class="cancl_tan_on_cont_ont">该手机号码已绑定其他微信，确认换绑到本微信？</div>
                </div>
                <div class="cancl_tan_on_bont">
                    <div class="cancl_tan_on_bont_lt" @click="cancle_no">取消绑定</div>
                    <div class="cancl_tan_on_bont_rt" @click="cancle_ok">确认换绑</div>
                </div>
            </div>
        </div>
        <!-- <div class="footer">
            <div @click="save" :style="is_click ? 'background:#aaa' : ''">确认</div>
        </div> -->
        <div class="bottom_back">
            <div class="bottom_back_on" @click="gobanck">
                <div class="bottom_back_wenz">返回</div>
                <div class="iconfont icon-fanhui"></div>
            </div>
        </div>
        <!-- 弹窗 -->
    </div>
</template>
<script>
import '@/assets/css/index.css'
import { Toast } from 'vant';
// import { send_sms_get } from "@/api/login";
// import { getInfo, postInfo, mobile_bind } from "@/api/home";
export default {
    data() {
        return {
            time: 0, // 倒计时
            is_branchbank: false,
            bank_name: '',
            bank_card_num: '',
            branch_bank: '',
            details: {},
            url: '',
            my_lizhi: false,
            mobile: '', // 手机号码
            code: '', // 验证码
            is_form: 'home', // home，apply: 从home，apply过来的要请求接口，info：过来的不需要请求接口
            is_click: false
        }
    },

    mounted() {
        if (this.$route.params.edit == 1) {
            // this.branch_bank = this.$route.params.branch_bank
            this.details.bank_name = this.$route.params.bank_name
            this.details.bank_card_num = this.$route.params.bank_card_num
            this.details.branch_bank = this.$route.params.branch_bank || ''
        }
        this.details = { ...this.details }
        window.scrollTo(0, 0)
        this.$store.commit("hideTabbar");
        this.$store.commit("getWxSDK");
        this.Request('ai_baidu/access_token', 'get').then(res => {
            this.bd_token = res.data.access_token
        })
        this.ISBranchBank();

    },
    methods: {
        gobanck() {
            this.$router.go(-1);
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function () {
                    imgResult = reader.result;
                };
                reader.onerror = function (error) {
                    reject(error);
                };
                reader.onloadend = function () {
                    resolve(imgResult);
                };
            });
        },
        getimg(e) {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration:0
            });
            this.getBase64(e.file).then(res => {
                let params = new FormData()
                params.append('image', res)
                params.append('access_token', this.bd_token)
                this.axios.defaults.headers["Content-Type"] = 'application/x-www-form-urlencoded'
                this.axios.post('https://aip.baidubce.com/rest/2.0/ocr/v1/bankcard', params).then(res => {
                    this.details.bank_name = res.data.result.bank_name
                    this.details.bank_card_num = res.data.result.bank_card_number.replace(/\D/g, '')
                    this.details.branch_bank = ''
                    this.details = { ...this.details }
                    Toast.clear()
                    if (res.data.result.bank_card_type == 0 || (!this.details.bank_name && !this.details.bank_card_num)) {
                        Toast('银行卡信息识别失败')
                    }
                }).catch(() => {
                    Toast.clear()
                    Toast('银行卡信息识别失败')
                    this.details.branch_bank = ''
                    this.details.bank_name = ''
                    this.details.bank_card_num = ''
                })

            })
        },
        save() {
            // 银行名称
            if (!!this.details.bank_name == false) {
                Toast('请输入银行名称');
                return
            }
            // 银行卡号
            if (!!this.details.bank_card_num == false) {
                Toast('请输入银行卡号');
                return
            }
            if (!!this.details.branch_bank == false && this.is_branchbank) {
                Toast('请输入开户行');
                return
            }
            let data = {}
            data.bank_name = this.details.bank_name
            data.bank_card_num = this.details.bank_card_num
            data.branch_bank = this.details.branch_bank || ''
            this.Request('client/mine/bank/info', 'put', JSON.stringify(data), true, false).then(res => {
                if (res.status == 0) {
                    this.$router.go(-1)
                }
                this.is_click = false
            })
        },
        //取消
        cancle_no() {
            this.my_lizhi = false;
        },
        // 开户行是否必填
        ISBranchBank() {
            this.Request('team/config', 'get', {
                team_id: localStorage.getItem('team_id')
            }).then(res => {
                if (res.status == 0 && res.data.branch_bank_required == 1) {
                    this.is_branchbank = true
                }
            })
        },
        cancle_ok() {
            this.Request('mobile_bind', 'put', { new_mobile: this.mobile }).then(res => {
                if (res.status == 0) {
                    this.my_lizhi = false;
                    this.details.mobile = this.mobile
                    // 是否是从立即报名过来的
                    if (this.is_form == 'apply') { // 是？跳转到填写报名信息页面
                        this.$router.replace({ path: '/recruitmentApply', query: { rid: this.$route.query.rid } })
                    } else if (this.is_form == 'info') {
                        this.$router.replace({ name: 'MyInfo', params: { details: JSON.stringify(this.details) } })
                    } else { // 返回上一页
                        this.$router.go(-1)
                    }
                }
            })
        }
    }
}
</script>
<style scoped>
.cont_fa_on_rt2 {
    width: calc(100% - 200px);
    height: 60px;
    line-height: 60px;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0;
    background: transparent;
}
.title_top_rt {
    width: 40px;
    height: 34px;
    background: url("../../assets/img/xiangji.png");
    background-size: 100% 100%;
}
.cont_fa {
    width: 100%;
    height: 110px;
    background: #ffffff;
    border-bottom: 1px solid #f8f8f8;
}
.upload {
    margin-top: 20px;
    border: 0;
    display: flex;
    background-color: #fff;
    align-items: center;
    justify-content: space-between;
    padding: 40px 30px 0;
    font-size: 30px;
    font-weight: 700;
}
.cont_fa_on {
    width: calc(100% - 60px);
    height: 110px;
    margin-left: 30px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.cont_fa_on_lt {
    height: 110px;
    line-height: 110px;
    text-align: left;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #1a1a1a;
}
.cont_fa_on_lts {
    height: 110px;
    line-height: 110px;
    text-align: left;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #1a1a1a;
}
.cont_fa_on_rt {
    width: calc(100% - 150px);
    height: 60px;
    line-height: 60px;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0;
    background: transparent;
}
.content_input {
    width: calc(100% - 80px);
    border-bottom: 2px solid #f8f8f8;
    height: 70px;
    margin-left: 40px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}
.content_input input {
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0;
    background: transparent;
}
.content_title {
    width: calc(100% - 80px);
    margin-left: 40px;
    margin-bottom: 22px;
    height: 40px;
    line-height: 40px;
    text-align: left;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #1a1a1a;
}
.btn_sty {
    width: calc(100% - 80px);
    margin: 120px auto;
    height: 80px;
    line-height: 80px;
    text-align: center;
    background: #0abbb9;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    opacity: 1;
    border-radius: 10px;
}
.content {
    /* padding-top: 110px; */
    margin: 0 30px;
}
/* input输入框样式 */
.cell {
    display: flex;
    border-bottom: 2px solid #f8f8f8;
    padding: 18px 0;
    position: relative;
}
.cell .left {
    display: flex;
    width: 28%;
}
.cell .left .must {
    color: red;
    margin: 6px 10px 0 0;
    width: 10px;
}
.cell .left .title {
    min-width: 132px;
    text-align-last: justify;
}
.cell .right {
    width: 75%;
    display: flex;
    justify-content: space-between;
}
.cell .right input {
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0;
    background: transparent;
}
.cell .right img {
    width: 30px;
    height: 30px;
    vertical-align: middle;
}
</style>